import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getLibrariesAsync,
  getLibrarySelectLibrariesAsync,
  sortLibrariesAsync,
} from '../../../atom/library/library.apis';
import {
  librariesState,
  libraryLoadingState,
  libraryMetaState,
} from '../../../atom/library/library.atoms';
import {
  librariesSelector,
  libraryMetaSelector,
} from '../../../atom/library/library.selectors';
import { Library } from '../../../atom/library/library.types';
import { useTopic } from '../../../atom/topic/topic.hook';
import { SearchAndTitle } from '../../../components/search-and-title/search-and-title.component';
import { TableComponent } from '../../../components/table/table.component';
import { LIBRARY_TYPE } from '../../../constants/default-value.const';
import { useSelectOption } from '../../../hooks/use-select-option.hook';
import { Meta } from '../../../types/global.types';
import {
  canShowAll,
  convertObjectArrayToString,
  removeUndefined,
  toFilterSelect,
  toSort,
  toString,
  toStringFilter,
} from '../../../utils/helper.utils';
import {
  messageSuccess,
  showMessageErrors,
} from '../../../utils/message.utils';
import { arrangeData } from '../../../utils/request.utils';
import { useTranslation } from 'react-i18next';
import { columnTableEllipsis } from '../../../utils/table.utils';
import { ListFeatureOption } from '../../../components/list-feature-option/list-feature-option.component';

const requestLibraries = (
  query: any,
  setLibraries: (items: Library[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  return getLibrariesAsync({ ...option, status: true })
    .then(({ items, meta }) => {
      setLibraries(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setLibraries([]);
      setMeta({});
      setLoading(false);
    });
};

export const LibraryModal: React.FC<any> = ({
  visible,
  onOk,
  onCancel,
  // title,
  type = 'QUESTION',
  questionType,
  onlyChildQuestion,
  selectionType,
  defaultFilter,
  isTest,
}) => {
  const setLibraries = useSetRecoilState(librariesState);
  const setMeta = useSetRecoilState(libraryMetaState);
  const libraries = useRecoilValue(librariesSelector);
  const meta = useRecoilValue(libraryMetaSelector);
  const [loading, setLoading] = useRecoilState(libraryLoadingState);
  // const { query } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [searchInfo, setSearchInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>('');
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const query: any = { ...defaultFilter, type, questionType, isTest };

  // query.type = type;
  const { topics, levels, subjects } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
  });
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    if (visible)
      requestLibraries(
        {
          ...option,
          type,
          questionType,
          query: searchQuery,
          onlyChildQuestion,
        },
        setLibraries,
        setMeta,
        setLoading
      );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      subjectId: toStringFilter(query.subjectId),
      levelId: toStringFilter(query.levelId),
      topicId: toStringFilter(query.topicId),
    });
    setTopicLevelId(query.levelId);
    setTopicSubjectId(query.subjectId);
    return () => {
      setSearchInfo({});
    };
  }, [
    searchQuery,
    type,
    questionType,
    onlyChildQuestion,
    setTopicSubjectId,
    setTopicLevelId,
    visible,
  ]);

  useEffect(() => {
    let data = [
      {
        title: t('Code'),
        //fixed: 'left',
        dataIndex: 'code',
        className: 'drag-visible',
        isSearchable: true,
        sorter: (a: Library, b: Library) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === 'code' && sortedInfo.order,
        sortDirections:  ['ascend', 'descend'],
        editable: false,
      },
      {
        title: t('Title'),
        //fixed: 'left',
        dataIndex: 'title',
        isSearchable: true,
        sorter: (a: Library, b: Library) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
        sortDirections:  ['ascend', 'descend'],
        editable: false,
      },
      {
        title: t('Video ID'),
        dataIndex: 'url',
        render: (text: string, record: Library) => record.data?.url,
      },
      {
        title: t('Question Type'),
        dataIndex: 'questionType',
        render: (text: string, record: Library) => record.data?.type,
      },
      {
        title: t('Subject'),
        dataIndex: 'subjectId',
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis('subject'),
      },
      {
        title: t('Level'),
        dataIndex: 'levelId',
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis('level'),
      },
      {
        title: t('Topic'),
        dataIndex: 'topicId',
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis('topic'),
      },
    ];

    data = data.filter((item) => {
      if (type === LIBRARY_TYPE.VIDEO && item.dataIndex === 'questionType') {
        return false;
      }
      if (type === LIBRARY_TYPE.QUESTION && item.dataIndex === 'url') {
        return false;
      }

      if (
        type === LIBRARY_TYPE.TEXT &&
        (item.dataIndex === 'questionType' || item.dataIndex === 'url')
      ) {
        return false;
      }

      return true;
    });
    setColumns(data);
  }, [filteredInfo, sortedInfo, type, subjects, levels, topics]);

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<Library>(data);
    sortLibrariesAsync(sortData)
      .then(() => {
        messageSuccess();
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
    } = data;

    const option = {
      ...query,
      ...searchInfo,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      query: searchQuery,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
    };

    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));
    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ subjectId, levelId, topicId });
    requestLibraries(option, setLibraries, setMeta, setLoading);
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
      query: searchQuery,
    });
    setSearchInfo({ searchField: column, searchValue: value });
    requestLibraries(option, setLibraries, setMeta, setLoading);
  };

  const onSelectedRows = (selectedRows: Library[]) => {
    setSelectedRows(selectedRows);
  };

  const _onOk = () => {
    if (selectedRows.length) {
      getLibrarySelectLibrariesAsync({
        ids: selectedRows.map((item: Library) => item.id),
      })
        .then((res) => {
          onOk(
            res.map((item: any) => {
              return {
                ...item,
                isNewRecord: true,
                libraryId: item.id,
                children: item.children.map((item2: any) => {
                  return { ...item2, libraryId: item2.id };
                }),
              };
            })
          );
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    } else {
      onCancel();
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const renderTitle = (type: string) => {
    if (type === LIBRARY_TYPE.QUESTION) return t('Question');
    if (type === LIBRARY_TYPE.VIDEO) return t('Video');
    if (type === LIBRARY_TYPE.TEXT) return t('Text Library');
    return t('List');
  };

  const onShowAll = () => {
    const option = {
      ...convertObjectArrayToString(filteredInfo),
      ...searchInfo,
      type,
      questionType,
      query: searchQuery,
      onlyChildQuestion,
      showAll: 'yes',
    };
    requestLibraries(option, setLibraries, setMeta, setLoading);
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchInfo({});
    requestLibraries(
      { type, questionType, query: searchQuery, onlyChildQuestion },
      setLibraries,
      setMeta,
      setLoading
    );
  };

  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      okText={t('Submit')}
      cancelText={t('Cancel')}
      onCancel={onCancel}
      maskClosable={false}
      width="80%"
      onOk={_onOk}
    >
      <SearchAndTitle title={renderTitle(type)} handleSearch={handleSearch} />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={canShowAll(searchInfo, filteredInfo)}
        query={query}
      />
      <TableComponent
        key={`table-${loading}-${libraries.length}`}
        dataSource={libraries}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        query={query}
        hasSelectedRows={true}
        onSelectedRows={onSelectedRows}
        selectionType={selectionType}
      />
    </Modal>
  );
};
